


.sheet {
    position: relative;
    display: block;
    margin: auto;
    margin-top: 25px;
    background-color: #FFFFFF;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(147,157,165,0.16), 0 2px 2px 0 rgba(147,157,165,0.24);
    overflow: hidden;
    width: 100%;
    max-width: 999px;
    height: auto;
    min-height: 600px;
    padding: 15px
}