


.app-box {
    font-family: 'Nunito Sans Variable', sans-serif;
    position: relative;
    display: block;
    margin: auto;
    background-color: #3A3E48;
    width: 100%;
    height: auto;
    min-height: 600px;
    max-width: 640px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 25px;
    margin-bottom: 25px;
    user-select: none;
}


.spinner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 640px;
    height: 200px;
    margin-top: 25px;
    background-color: #3A3E48;
    user-select: none;
}

.spinner {
    border: 10px solid rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-left-color: rgb(168, 168, 168);
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


.save-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #4B4E57;
    padding: 2px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;

    &:hover {
        background-color: #666971;
        transition: background-color 0.2s ease;
    }

    svg {
        width: 22px;
        fill: #fff;
    }
}

.back-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #4B4E57;
    padding: 2px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    //transform: translate3d(-15px, -40px, 0);

    &:hover {
        background-color: #666971;
        transition: background-color 0.2s ease;
    }

    svg {
        width: 22px;
        fill: #fff;
    }
}

.avatar {
    display: block;
    margin: auto;
    width: 125px;
    height: 125px;
    border-radius: 100%;
    //background-image: url(avatar.png);
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.username {
    text-align: center;
    margin-top: 10px;
    font-size: 32px;
}

.configs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 50px;

    svg {
        width: 22px;
        fill: #fff;
    }
}

.config-ico {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .icos {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #4B4E57;
        padding: 2px;
        height: 50px;
        width: 50px;
        border-radius: 100%;

        &:hover {
            background-color: #666971;
            transition: background-color 0.2s ease;
        }
    }
}

.ico-txt {
    font-size: 15px;
}

.line {
    display: block;
    margin: auto;
    height: 2px;
    width: 100%;
    background-color: #fff;
    margin-top: 100px;
    margin-bottom: 25px;
}


.games {
    text-align: center;
    font-size: 25px;
    margin-bottom: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

     div {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            cursor: pointer;
            transition: transform 0.2s ease;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

}


.game-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .game-item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 25px;

        background-color: #353942;
        width: 100%;
        height: 100px;
        box-sizing: border-box;
        padding: 5px;

        border-bottom: 4px solid transparent;
        transition: border-color 0.2s ease;

        &:hover {
            border-color: #10B1F5;
            cursor: pointer;
        }
    }

    .game-img {
        width: 80px;
        height: 80px;
        background-color: #4B4E57;

        img {
            display: block;
            margin: auto;
            width: 80px;
            height: 80px;
            object-fit: cover;
        }

    }

    .tag {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 5px;
    }

    .console-tag {
        box-sizing: border-box;
        padding: 5px;
        border-radius: 10px;
        border: 2px solid #fff;
        font-size: 12px;
        min-width: 60px;
        text-align: center;
    }

    .edit-game {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 5px;
        padding-right: 10px;

        svg {
            width: 25px;
            fill: #fff;
            transition: transform 0.2s ease;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

    .trophy-count {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        svg {
            fill: #fff;
            width: 25px;
            height: 25px;
        }

    }
}


.game-title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.trophy-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .trophy-item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 25px;

        background-color: #353942;
        width: 100%;
        height: 100px;
        box-sizing: border-box;
        padding: 5px;

        border-bottom: 4px solid transparent;
        transition: border-color 0.2s ease;

        &:hover {
            border-color: #10B1F5;
            cursor: pointer;
        }
    }

    .trophy-img {
        width: 80px;
        height: 80px;
        //background-color: #4B4E57;
        img {
            display: block;
            margin: auto;
            width: 80px;
            height: 80px;
            object-fit: cover;
        }

    }

    .trophy-info {
    }

    .trophy-name {
        font-size: 15px;

    }
    .trophy-desc {
        font-size: 12px;
        color: #D6D7D8;
    }

    .trophy-ico {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        svg {
            fill: #fff;
            width: 25px;
            height: 25px;
        }

    }

    .trophy-earned {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover svg path {
            fill: #A8A8A8;
          }
    }

    .edit-trophy {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 5px;
        padding-right: 10px;

        svg {
            width: 25px;
            fill: #fff;
            transition: transform 0.2s ease;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

}


.hover-info {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 125px;
    background-color: #4B4E57;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    z-index: 999;
}

.hover-info-left {
    position: absolute;
    top: 50%;
    left: -110px;
    transform: translateY(-50%);
    width: 100px;
    background-color: #4B4E57;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    z-index: 999;
}

.hover-info-right {
    position: absolute;
    top: 50%;
    right: -110px;
    transform: translateY(-50%);
    width: 100px;
    background-color: #4B4E57;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    z-index: 999;
}



.edit-profile-title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-profile-input {
    height: 35px;
    background: transparent;
    border: 1px solid #D6D7D8;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    color: #D6D7D8;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;

    &:focus {
        outline: 1px solid #D6D7D8;
    }

    &::placeholder {
        color: #A8A8A8;
        font-weight: 500;
    }
}

.salvar-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 35px;
    background-color: #4B4E57;
    color:#fff;
    font-weight: 500;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
        background-color: #666971;
        transition: background-color 0.2s ease;
    }
}

.error {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: auto;
    height: 35px;
    width: 100%;
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
}

