body {
    background-color: #816dbb;
}

.resume-content {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 1280px;
    min-height: 100vh;
    height: auto;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 3px 3px 1px rgba(0,0,0,0.25);
}

.resume-grid {

    display: grid;
    grid-template-columns: 50% 50%;

    .col-left {
        position: relative;
        padding: 5px;
        background-color: #735cb5;
    }
    
    .col-right {
        padding: 5px;
        color: #3f3843;
        background-color: #fff;
    }

}

.item-title {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 5px;
    box-sizing: border-box;
}

ul {
    list-style: none;
    padding-left: 20px;
}


.item {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;

    .item-name {
        font-size: 15px;
        font-weight: 600;
    }
    .item-level {
        height: 100%;
        padding: 6px 5px;

        .level-bg {
            height: 100%;
            background-color: #735189;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            padding: 6px;

            .level-inside {
                background-color: #917ccc;
                width: 0%;
                height: 100%;
                color: #fff;
                font-size: 15px;
                font-weight: bold;
                text-align: center;
            }

        }
    }
}


.avatar {
    display: block;
    width: 250px;
    height: 250px;
    margin: auto;
    background: #fff;
    border-radius: 200px;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 20px;

    img {
        display: block;
        margin: auto;
        width: 100%;
        border-radius: 200px;
    }

}

.name-box {
    display: block;
    margin: auto;
    width: 100%;
    background: #917ccc;
    color: #fff;
    text-align: center;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 5px;
    margin-bottom: 30px;

    .nick {
        font-size: 22px;
        padding: 3px 0;
        font-weight: 700;
    }

    .job {
        text-transform: uppercase;
        font-size: 20px;
        padding: 3px 0;
        font-weight: 700;
    }
}

.bio-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px   ;
    margin: auto;
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
    text-align: center;
    color: #fff;
}

.mini-bio {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    padding: 15px 35px;
    text-align: justify;
    color: #fff;
}

.info-list {
    display: grid;
    margin: auto;
    width: 100%;
    max-width: 600px;
    grid-auto-flow: row;
    box-sizing: border-box;
    padding: 15px 35px;
    text-align: justify;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    justify-content: center;
    align-items: center;
    color: #fff;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }

}

.change-language {
    position: relative;
    display: block;
    width: 150px;
    height: 25px;
    font-size: 15px;
    color: #fff;
    background-color: rgba(0,0,0,0.25);

    .ico {
        position: absolute;
        top: 4px;
        left: 5px;
    }

    .selected {
        position: absolute;
        top: 1px;
        left: 35px; 
        align-items: center;
        flex-direction: column;
        height: 25px;
        text-align: center;
        width: 80px;
        cursor: pointer
    }

    .selection {
        position: absolute;
        top: 26px;
        left: 0;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(0,0,0,0.25);
        cursor: pointer;

        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 25px;
            &:hover {
                text-decoration: underline;
            }
        }

    }

}

.download-cv {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 25px;
}

@media (max-width: 800px) {
    .resume-grid {
        display: grid;
        grid-template-columns: 100%;
    }
  }
  
  @media (min-width: 801px) {
    .resume-grid {
        display: grid;
        grid-template-columns: 50% 50%;
    }
  }

  @media print {
    @page {
      size: landscape;
    }
  }